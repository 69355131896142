/* You can add global styles to this file, and also import other style files */

// @import "assets/sass/main.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

@import "./assets/css/row.css";
@import "./assets/css/alignment.css";
@import "./assets/css/margin-padding.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');

html,
body {
    height: 100%;
    overflow: hidden;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    font-size: 15px;
}


.required:after {
    content: " *";
    color: red;
    font-size: 14px;
}

.err {
    font-size: 9px;
    color: red;
    margin-left: 2px;
}